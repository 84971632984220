<template>
  <div>
    <b-card>
      <template #header>
        <card-header
          title="Dati dell'azienda"
          :button="{
              content: editButtonText,
              variant: 'info',
              classes: schedaSectionButtonClasses,
            }"
          :button-action="toggleEditMode"
        />
      </template>

      <div>
        <b-form @submit.prevent="submitForm">
          <b-form-row>
            <b-col md="3">
              <div :class="formImgClasses">
                <div class="form-img__img" :style="bgStyle"></div>
                <b-button
                  class="form-img__btn"
                  variant="link"
                  @click="openModalImageUpload"
                >
                  <font-awesome-icon
                    icon="plus"
                    class="fa-fw"
                  />
                </b-button>
              </div>
            </b-col>
            <b-col class="mt-3 mt-md-0" md="9">
              <b-form-row>
                <b-col md="6" lg="4">
                  <field-text
                    :read-only="isDisabled"
                    fieldId="companyName"
                    placeholder="Ragione Sociale"
                    title="Ragione Sociale"
                    :state="validateState($v.formCompanyData.info.companyName)"
                    v-model="$v.formCompanyData.info.companyName.$model"
                  />
                </b-col>
                <b-col md="6" lg="4">
                  <field-text
                    :read-only="isDisabled"
                    fieldId="vat"
                    placeholder="Partita IVA"
                    title="Partita IVA"
                    :state="validateState($v.formCompanyData.info.vatNumber)"
                    v-model="$v.formCompanyData.info.vatNumber.$model"
                  />
                </b-col>
                <b-col md="12" lg="4">
                  <b-form-group label="ATECO" :class="atecoFormClasses">
                    <b-input-group class="input--alt-style">
                      <b-form-input
                        type="text"
                        :value="atecoCode"
                        :disabled="readOnly"
                        readonly
                        invalid-feedback
                        class="test"
                      />
                      <b-input-group-append v-if="editMode">
                        <b-button class="btn-info" @click="openAtecoModal" :disabled="readOnly">
                          <font-awesome-icon icon="search"></font-awesome-icon>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback v-if="atecoCheck">
                      Il codice ATECO è obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <div>
                <label class="col-form-label col-form-label--accent">sede legale</label>
                <div>
                  <b-form-row>
                    <b-col md="12">
                      <field-address
                        :read-only="isDisabled"
                        field-id="legalAddress"
                        v-model="$v.formCompanyData.legalAddress.$model"
                        :state="fieldStateAddress"
                      />
                    </b-col>
                  </b-form-row>
                </div>
              </div>
              <div>
                <field-billing
                  :read-only="isDisabled"
                  title="Dati di fatturazione"
                  field-id="invoiceReference"
                  v-model="$v.formCompanyData.invoiceReference.$model"
                  :state="fieldStateBilling"
                  is-company
                />
              </div>
              <div>
                <label class="col-form-label col-form-label--accent">Documenti</label>
                <div>
                  <document-upload
                    v-if="hasDocuments"
                    :on-click="onUploadChamberDoc"
                    :document="currentChamberDoc"
                    :read-only="isDisabled"
                    entity-type="COMPANY"
                    header-alt
                    :enable-upload="!isDisabled"
                    slim
                  />
                </div>
              </div>
            </b-col>
          </b-form-row>
          <div class="p-2 bg-gray-200 text-right" v-if="!isDisabled">
            <button-loader
              buttonStyle="success"
              buttonClass="text-uppercase btn-success"
              content="Salva"
              :disabled="$v.formCompanyData.$invalid"
              :loading="submitted"
            />
          </div>
        </b-form>
      </div>
    </b-card>
    <modal-document-upload
      v-if="hasDocuments"
      id="companyDocs"
      entity-type="COMPANY"
      :entity-id="formCompanyData.companyId"
      :document-name="documentToUpload.documentName"
      :document="documentToUpload.document"
      :on-success="updateDoc"
    />
    <modal-image-upload
      id="userImage"
      entity-type="COMPANY"
      :entity-id="formCompanyData.companyId"
      image-type="PROFILE"
    />
    <modal-ateco
      id="atecoModal"
      :assign-ateco="setAteco"
    />
    <modal-image-upload
      v-if="hasDocuments"
      id="companyImage"
      entity-type="COMPANY"
      :entity-id="formCompanyData.companyId"
      image-type="PROFILE"
    />
  </div>
</template>

<script>
import lodash from 'lodash';
import { required } from 'vuelidate/lib/validators';
import {
  decodeFormFieldError,
  fieldStateAddress,
  isNotEmpty,
  omitNull,
  validateFormFieldState,
  isPresent,
  convertSetFieldName,
  decodeErrorMessage,
  serverError,
  VALIDATE_ADDRESS,
  removeProp,
} from '@/utils/validators';
import { createPublicUri } from '@/utils/utilities';

const ButtonLoader = () => import('@/components/ButtonLoader.vue');
const ModalAteco = () => import('@/components/userSections/ModalAteco.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');
const FieldBilling = () => import('@/components/fields/FieldBilling.vue');
const FieldText = () => import('@/components/fields/FieldText.vue');
const FieldAddress = () => import('@/components/fields/FieldAddress.vue');
const ModalDocumentUpload = () => import('@/components/documents/ModalDocumentUpload.vue');
const DocumentUpload = () => import('@/components/documents/DocumentUpload.vue');
const ModalImageUpload = () => import('@/components/userSections/ModalImageUpload.vue');

export default {
  name: 'FormCompanyData',
  components: {
    ButtonLoader,
    FieldText,
    FieldAddress,
    ModalDocumentUpload,
    DocumentUpload,
    ModalImageUpload,
    ModalAteco,
    CardHeader,
    FieldBilling,
  },
  props: {
    readOnly: Boolean,
  },
  created() {
    this.populateForm();
  },
  data() {
    return {
      submitted: false,
      formCompanyData: null,
      documentToUpload: {
        document: null,
        documentName: null,
        documentType: null,
      },
      chamberDoc: null,
      hasCompany: false,
      editMode: false,
      clientValidation: {
        formCompanyData: {
          info: {
            companyName: {
              required,
            },
            vatNumber: {
              required,
            },
          },
          // attribute: {
          //   atecoCode: {
          //     required,
          //   },
          // },
          legalAddress: {
            ...VALIDATE_ADDRESS,
          },
          invoiceReference: {},
        },
      },
      serverValidation: {},
    };
  },
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
    fieldStateAddress() {
      return fieldStateAddress(this.$v.formCompanyData.legalAddress);
    },
    fieldStateBilling() {
      // return fieldStateAddress(this.$v.formCompanyData.billingInformation);
      return {};
    },
    hasDocuments() {
      return this.$store.getters['documents/hasDocumentList']('company', this.formCompanyData.companyId);
    },
    currentChamberDoc() {
      if (this.chamberDoc === null && this.hasDocuments) {
        this.setChamberDoc();
      }
      return this.chamberDoc;
    },
    isDisabled() {
      return (this.readOnly || !this.editMode);
    },
    icon() {
      return this.editMode ? 'times' : 'pen';
    },
    schedaSectionButtonClasses() {
      if (this.readOnly) return 'd-none';
      return 'scheda-section--has-action__button scheda-section--has-action__button--is-active';
    },
    hasChamberDocument() {
      return isNotEmpty(this.currentChamberDoc);
    },
    imgPath() {
      const imageProfile = this.$store.getters['subject/getImage']('PROFILE');
      return isNotEmpty(imageProfile) ? imageProfile.documentUpload.externalId : '';// set img here
    },
    formImgClasses() {
      const base = 'form-img form-img--company';
      if (isPresent(this.imgPath)) return `${base} has-img`;
      return base;
    },
    bgStyle() {
      // img check, change condition as needed
      if (isPresent(this.imgPath)) {
        return `background-image: url(${createPublicUri(this.imgPath)});`;
      }
      return '';
    },
    editButtonText() {
      if (this.editMode) {
        return 'Annulla modifiche';
      }
      return 'Compila';
    },
    atecoCode() {
      // const company = this.$store.getters['subject/currentCompany'];
      if (isPresent(this.formCompanyData.attribute.atecoCode)) {
        const companyAteco = `${this.formCompanyData.attribute.atecoCode} ${this.formCompanyData.atecoInfo.description}`;
        return companyAteco;
      }
      return '';
    },
    atecoCheck() {
      if (this.editMode && this.atecoCode === '') return true;
      return false;
    },
    atecoFormClasses() {
      if (this.atecoCheck) {
        return 'field-ateco is-invalid';
      }
      return 'field-ateco';
    },
  },
  validations() {
    return this.rules;
  },
  methods: {
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    openModalUpload(doc, documentName, documentType) {
      this.documentToUpload = {
        document: doc,
        documentName,
        documentType,
      };
      this.$bvModal.show('companyDocs');
    },
    setChamberDoc() {
      this.chamberDoc = this.$store.getters['documents/getDocument'](
        'company', this.formCompanyData.companyId, 'GENERIC', 'CHAMBER_COMMERCE',
      ) || { documentInfo: { documentType: 'CHAMBER_COMMERCE', documentEntityType: 'GENERIC' }, documentUpload: {} };
    },
    populateForm() {
      // console.log('company creaed');
      const company = this.$store.getters['subject/currentCompany'];
      // console.log('company', company);
      // const company = this.$store.getters['subject/currentProfile'];
      const deepcloned = lodash.cloneDeep(company);
      // const docIdentity = this.$store.getters['subject/currentLegalDoc'];
      const empty = {
        info: {
          companyName: null,
          vatNumber: null,
        },
        attribute: {
          atecoCode: null,
        },
        legalAddress: {
          type: 'LEGAL',
          countryCode: null,
          streetName: null,
          zip: null,
          city: null,
          province: null,
        },
        invoiceReference: {
          taxCode: null,
          vatNumber: null,
          sdiCode: null,
          invoicePecEmail: {
            type: 'PEC',
            contactData: null,
          },
        },
      };

      if (isNotEmpty(deepcloned)) {
        this.formCompanyData = { ...empty, ...omitNull(deepcloned) };
        // if (isNotEmpty(docIdentity) && isNotEmpty(docIdentity.additionalInfos)) {
        //   this.$set(this.formCompanyData.docIdentity, 'docNumber', docIdentity.additionalInfos.docNumber);
        //   this.$set(this.formCompanyData.docIdentity, 'expireDate', docIdentity.additionalInfos.expireDate);
        //   // this.formCompanyData.docIdentity.docNumber = docIdentity.additionalInfos.docNumber;
        //   // this.formCompanyData.docIdentity.expireDate = docIdentity.additionalInfos.expireDate;
        // }
        this.hasCompany = true;
      } else {
        this.formCompanyData = { ...empty };
      }
      this.$v.$touch();
    },
    processError(errors) {
      if (!errors) {
        this.submitted = false;
        return;
      }
      console.log('errors', errors);
      const serverMessages = {
        serverErrors: {
        },
      };
      this.serverValidation = {
        formCompanyData: {
        },
      };

      errors.forEach((error) => {
        const { field, code } = error;
        if (isPresent(field)) {
          convertSetFieldName(serverMessages.serverErrors, field, decodeErrorMessage(field, code));
          convertSetFieldName(this.serverValidation.formCompanyData, field, {
            serverError: serverError(field, false, this.formCompanyData),
          });
        }
      });

      // Merge the server errors into the data. This doesn't yet cause
      // any validation errors but does make the messages (and therefore state)
      // accessible to the template.
      lodash.merge(this.formCompanyData, serverMessages);

      this.submitted = false;
    },
    clearServerErrors() {
      // Clearing the server validation removes the rules and
      // therefore server errors no longer contribute to validation state.
      this.serverValidation = {};
      // Clearing the server errors from the data isn't necessary either
      // but again makes for good housekeeping and we'd need to do this
      // if submitting this data to the server.
      removeProp(this.formData, 'serverErrors');
    },
    clearServerError(model, fieldName) {
      // When an input control is modified we see if there are any
      // server errors and clear them. This causes the serverError validation
      // rule to trigger and clear it's error state.
      // eslint-disable-next-line no-prototype-builtins
      if (model.hasOwnProperty('serverErrors')) {
        // eslint-disable-next-line no-prototype-builtins
        if (model.serverErrors.hasOwnProperty(fieldName)) {
          // eslint-disable-next-line no-param-reassign
          delete model.serverErrors[fieldName];
        }
      }
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
      if (!this.editMode) {
        this.$v.$reset();
        if (!this.submitted) {
          this.populateForm();
        }
      }
    },
    submitForm() {
      if (this.isDisabled) return;
      this.clearServerErrors();
      if (this.$v.$invalid) return;

      console.log('submitForm', this.formCompanyData);
      const { docIdentity, ...company } = this.formCompanyData;
      company.legalAddress.state = company.legalAddress.province;
      this.submitted = true;
      this.$store.dispatch('subject/saveCompany', company).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          // const doc = this.$store.getters['subject/currentIdentityDoc'];
          // const toUpdate = { ...doc, additionalInfos: { ...docIdentity } };
          // this.$store.dispatch('documents/updateDocument', {
          //   document: toUpdate,
          //   entityClass: 'COMPANY',
          //   entityId: company.companyId,
          // }).then((docUpdated) => {
          //   this.$store.dispatch('subject/updateCompanyDocs', { legalDoc: docUpdated });
          //   this.toggleEditMode();
          //   this.submitted = false;
          // },
          // (error) => {
          //   this.message = extractErrorMessage(error);
          //   this.submitted = false;
          // });
          console.log('company saved');
          this.toggleEditMode();
          this.submitted = false;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          // this.message = this.processError(error);
          this.submitted = false;
        },
      );
    },
    updateDoc(docUpdated) {
      this.chamberDoc = docUpdated;
    },
    openAtecoModal() {
      this.$bvModal.show('atecoModal');
    },
    onUploadChamberDoc() {
      this.openModalUpload(this.currentChamberDoc, 'Visura Camerale', 'currentChamberDoc');
    },
    openModalImageUpload() {
      this.$bvModal.show('companyImage');
    },
    setAteco(atecoInfo) {
      if (isNotEmpty(atecoInfo)) {
        this.formCompanyData.atecoInfo = atecoInfo;
        const { classify, category, subcategory } = atecoInfo;
        this.formCompanyData.attribute.atecoCode = subcategory || category || classify;
      } else {
        delete this.formCompanyData.atecoInfo;
        this.formCompanyData.attribute.atecoCode = null;
      }
    },
  },
};
</script>
